<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box land-info">
            <div class="land-info__header">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <el-carousel indicator-position="outside">
                            <el-carousel-item v-for="(item, index) in landPics" :key="index">
                                <img :src="item+'?w=600'" alt="" style="width: 100%; height: 100%" />
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col :span="14">
                        <h3 class="land-info__title">{{ landInfo.title }}</h3>

                        <el-button v-if="landInfo.auditStatus == 2" type="text" :icon="haveFavorite?'el-icon-star-on' : 'el-icon-star-off'" class="fav-btn" @click="addFavorite">{{ haveFavorite?'取消收藏':'收藏' }}</el-button>
                        <el-button v-if="landInfo.auditStatus == 3" type="danger" class="fav-btn" style="font-size: 18px;color: whitesmoke">审核不通过</el-button>
                        <el-button v-if="landInfo.auditStatus == 1" type="danger" class="fav-btn" style="font-size: 18px;color: whitesmoke">未审核</el-button>
                        <p class="land_info__item">
                            <span>
                                <i class="ti ti-map-pin"></i>
                                四川省/德阳市/{{ findLabel(land_area,landInfo.regionId) }}
                            </span>
                            <span>
                                <i class="ti ti-clock-hour-3"></i>
                                {{ landInfo.createTime }}
                            </span>
                        </p>



                        <div class="price-panel" v-if="!landInfo.agreementTransaction  && landInfo.landSupplyType!=5">
                            <span class="label">价格：</span>
                            <span class="price-text" v-if="landInfo.price > 0">￥{{ landInfo.price }}万元</span>
                            <span class="price-text" v-else>面议</span>
                        </div>
                        <div class="price-panel" v-if="landInfo.agreementTransaction && landInfo.agreementTransaction.transactionStatus === 'finish'">
                            <span class="label">成交价格：</span>
                            <span class="price-text">￥{{ landInfo.agreementTransaction.amount }}万元</span>
                        </div>

                      <p class="land_info__item">
                            <span style="width: 200px;">
                                 所属行政区： {{ findLabel(land_area,landInfo.regionId) }}
                            </span>
                        <span style="width: 200px;">
                                土地面积： {{landInfo.landSize}}平方米
                            </span>
                      </p>


                        <p class="land_info__item">
                            <span  style="width: 200px;">
                                土地用途： {{ findLabel(demand_purpose,landInfo.landPurpose) }}

                                {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}
                            </span>

                            <span style="width: 200px;">
                                <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                                交易方式： {{ findLabel(transaction_way,landInfo.transactionWay) }}
                            </span>
                        </p>

                        <p class="land_info__item">
                            <span>
                                <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                                联系方式：<el-tooltip v-if="isLogin" :content="landInfo.phoneNumber" placement="top" effect="light">
                                    <el-link>{{landInfo.phoneNumber}} </el-link>
                                </el-tooltip>

                                <el-tooltip v-if="!isLogin" content="点击登录 " placement="top" effect="light">
                                    <el-link>登录查看 </el-link>
                                </el-tooltip>
                            </span>
                        </p>
                        <p class="land_info__item">
                            <span>
                                <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                                <RouterLink target="_blank" to="/services">委托交易</RouterLink>&nbsp;&nbsp;&nbsp;
                                <RouterLink target="_blank" to="/service-guide?id=1666269628714201127">合同服务</RouterLink>
                            </span>
                        </p>
                      <p>
                        <span>

                          <el-button type="text" style="width: 130px;height: 44px;;background: white;font-size: 18px;border: 1px solid #2b70c2;border-radius: 0px;color: #2b70c2;text-align: center;margin-left:-5px;cursor: pointer;" @click="kd_show(1)">预约看地</el-button>
 <el-button type="text" style="width: 130px;height: 44px;background: white;border: 1px solid #2b70c2;font-size: 18px;border-radius: 0px;color: #2b70c2;text-align: center;cursor: pointer;margin-left: 23px;" @click="kd_show(2)">意向报价</el-button>
                        </span>
                      </p>


                    </el-col>
                </el-row>
            </div>

            <div class="land-info__detail">
                <div class="land-info__detail__nav">
                    <a @click="scrollInto('info')">土地信息</a>
                    <a @click="scrollInto('img')">土地图片</a>
                    <a @click="scrollInto('map')">土地地图</a>
                    <a @click="scrollInto('land_planning')">土地详规</a>
                    <a v-if="landInfo.vr" @click="gotoLink(landInfo.vr)">VR</a>
                </div>


                <div id="info">
                    <el-descriptions title="土地信息" :column="2" border>

                        <el-descriptions-item label="土地用途" label-align="right" align="center" width="150px">
                            {{ findLabel(demand_purpose,landInfo.landPurpose) }}
                            {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}
                        </el-descriptions-item>

                        <el-descriptions-item label="交易方式" label-align="right" align="center" width="150px">{{ findLabel(transaction_way,landInfo.transactionWay) }}</el-descriptions-item>
                        <el-descriptions-item v-if="false" label="土地用途2" label-align="right" align="center" width="150px">{{ findLabel(demand_purpose,landInfo.landPurpose2) }} - {{getLandPurposeItemName(landInfo.landPurpose2, landInfo.subLandPurpose2)}}</el-descriptions-item>
                        <el-descriptions-item label="所属行政区" label-align="right" align="center">{{ findLabel(land_area,landInfo.regionId) }}</el-descriptions-item>
                        <el-descriptions-item label="土地性质" label-align="right" align="center">{{ findLabel(land_type,landInfo.ownerType) }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="权属单位" label-align="right" align="center">{{landInfo.ownerOrg}}</el-descriptions-item>-->
                        <el-descriptions-item v-if="landInfo.landSupplyType!=5" label="权证类型" label-align="right" align="center">{{findLabel(land_license_type,landInfo.licenseNoType)}}</el-descriptions-item>
                        <!--                      <el-descriptions-item label="权证编号" label-align="right" align="center">{{landInfo.licenseNo}}</el-descriptions-item>-->
                        <el-descriptions-item v-if="landInfo.landSupplyType!=5" label="使用权截止日期" label-align="right" align="center">{{landInfo.landDeadLine}}</el-descriptions-item>
                        <el-descriptions-item label="土地面积" label-align="right" align="center">{{landInfo.landSize}}平方米</el-descriptions-item>
                        <el-descriptions-item label="容积率" label-align="right" align="center">{{landInfo.volumeRate}}</el-descriptions-item>
                        <!-- <el-descriptions-item label="地上建筑物类型" label-align="right" align="center"></el-descriptions-item>-->
                        <el-descriptions-item label="土地位置" label-align="right" align="center">{{landInfo.landPosition}}</el-descriptions-item>
                        <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑类型" label-align="right" align="center">{{landInfo.buildingType}}</el-descriptions-item>
                        <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑面积" label-align="right" align="center">{{landInfo.buildingSize}}平方米</el-descriptions-item>
                        <el-descriptions-item label="" label-align="right" align="center">  </el-descriptions-item>
                        <el-descriptions-item label="土地描述" span="3"><div style="max-width: 500px" v-html="landInfo.desc"></div></el-descriptions-item>
                        <el-descriptions-item label="未来规划" span="3"><div style="max-width: 500px" >{{landInfo.region=="" || landInfo.region==null? "":landInfo.region.replace("_","")}}
                          </div></el-descriptions-item>
                    </el-descriptions>
                </div>

                <div id="land_planning" style="    padding: 22px 18px;">

                    <el-descriptions title="土地详规" :column="1" border>
                      <el-descriptions-item label="土地详规" span="3" width="150px"><a v-if="false" target="_blank" :href="findLabel(land_area,landInfo.regionId)=='市本级'?'https://dytudi.com.cn/#/news-detail?id=1666269628714201224':''">{{ findLabel(land_area,landInfo.regionId)=="市本级"? findLabel(land_area,landInfo.regionId)+"土地政策详规":""}}</a></el-descriptions-item>
                    </el-descriptions>
                </div>
                <div id="img">
                    <el-descriptions title="土地图片" :column="1"></el-descriptions>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="(item,index) in landPics" :key="index">
                            <img :src="item+'?w=600'" width="100%" />
                        </el-col>
                    </el-row>
                </div>
                <div id="map">
                    <el-descriptions title="土地地图" :column="1"></el-descriptions>
                    <Map :landInfo="landInfo" />
                </div>
              <div id="map">
                <el-descriptions title="意向报价排名" :column="1"></el-descriptions>
                <div class="layout-padding-auto layout-padding-view">

                  <el-table :data="data.tableData" style="width: 100%">

                    <el-table-column prop="resourceNo" label="用户名"    show-overflow-tooltip >
                      <template #default="scope">
                        {{ scope.row.xm}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="landInfo.regionId" label="电话" show-overflow-tooltip>
                      <template #default="scope">
                        {{scope.row.dh}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="startPrice" label="报价次数"  show-overflow-tooltip>
                      <template #default="scope">
                        {{ scope.row.cs}}
                      </template>
                    </el-table-column>

                    <el-table-column prop="releaseTime" label="最新报价时间"  show-overflow-tooltip />

                  </el-table>
                  <div class="user-info-list-page">
                    <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
        </div>


        <footer2 />


      <div data-v-02e9ab7c="" class="delDialog_box delDialog_box_new" id="delDialog_box_new" style="display: none">
        <div data-v-02e9ab7c="" class="delDialog" style=""></div>
        <div data-v-02e9ab7c="" class="transition-box container_box" style="width: 450px; height: auto; top: 30%; transform: translate(-50%, 1%); z-index: 1000;">
          <div data-v-02e9ab7c="" class="title_box" style="text-align: left;">
            <h4 data-v-02e9ab7c="" id="div_title">预约看地</h4>
            <div data-v-02e9ab7c="" class="contractClose"></div>
          </div>
          <div data-v-02e9ab7c="" style="padding: 20px 20px 0px;">
            <form data-v-02e9ab7c="" class="el-form subscribe-ruleForm el-form--label-right">
              <div data-v-02e9ab7c="" class="el-form-item is-success is-required"><label for="name" class="el-form-item__label" style="width: 80px;">姓名</label>
                <div class="el-form-item__content" style="margin-left: 80px;">
                  <div data-v-02e9ab7c="" class="el-input">
                    <!----><input type="text" autocomplete="off" class="el-input__inner" id="div_name">

                  </div>
                </div>
              </div>
              <div data-v-02e9ab7c="" class="el-form-item is-success is-required"><label for="phone" class="el-form-item__label" style="width: 80px;">手机号</label>
                <div class="el-form-item__content" style="margin-left: 80px;">
                  <div data-v-02e9ab7c="" class="el-input">
                    <!----><input type="text" autocomplete="off" class="el-input__inner" id="div_tel">

                  </div>
                  <!---->
                </div>
              </div>
              <div data-v-02e9ab7c="" class="el-form-item is-required"><label class="el-form-item__label" style="width: 80px;" id="div_lable">看地时间</label>
                <div class="el-form-item__content" style="margin-left: 80px;">
                  <div data-v-02e9ab7c="" class="el-input" id="div_number">
                    <!----><input type="text" autocomplete="off" class="el-input__inner" id="user_text">

                  </div>

                  <div data-v-02e9ab7c="" class="el-form-item is-required" id="div_time" >
                    <!---->
                    <div class="el-form-item__content">
                      <div data-v-02e9ab7c=""  class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime">

                        <!----><input type="date" autocomplete="off" name="" id="user_date" placeholder="选择日期" style="  width: 330px;" class="el-input__inner"><span class="el-input__prefix"><i class="el-input__icon el-icon-time"></i>
                                    </span><span class="el-input__suffix"><span class="el-input__suffix-inner"><i class="el-input__icon"></i>
                                        </span>
                                    </span>
                      </div>
                      <!---->
                    </div>
                  </div>
                  <!---->
                </div>
              </div>
              <div data-v-02e9ab7c="" class="m-l-12 m-b-6">
                <div data-v-02e9ab7c="" style="    background: #2b70c2; color: #fff;  border: 1px solid #2b70c2;" @click="kd_from()" class="epf_btn epf_btn_bg epf_subscribe_btn">提交</div>
                <div data-v-02e9ab7c="" style="border: 1px solid #dfdfdf" class="epf_btn epf_btn_def epf_subscribe_btn" @click="kd_hide(1)">取消</div>
              </div>
            </form>
          </div>
        </div>
      </div>




    </div>




</template>
<script lang="ts">
    import LandHeader from "@/components/nav";
    import footer2 from "components/footer.vue";
    import Map from "@/components/TMap.vue";
    import CommonService from "../service/CommonService";
    import { useDict, findLabel } from "@/hooks/dict";
    import {ref, nextTick, reactive} from "vue";
    import '@/assets/css/land-info.scss'
    import { RouterLink } from "vue-router";

    export default {
        name: "LandInfo",
        components: {
            RouterLink,
            LandHeader,
            footer2,
            Map
        },
        data() {
            return {
                data :{
                pageSize: 10,
                div_type: 1,
                currentPage: 1,
                totalPage: 1,
                  userId:"",
                tableData: []
          },
                mapMode: '地图',
                haveFavorite: false,
                clickState: false,
                dialogFormVisible: true,
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: '',
                },
                landInfo: {
                    id: "",
                    title: "",
                    landPurpose2: "",
                    agreementTransaction: {
                        amount: ''
                    },
                    subLandPurpose: "",
                    subLandPurpose2: "",
                    landPics: '',
                    desc: '',
                    regionId: '',
                    createTime: '',
                    price: '',
                    landPurpose: '',
                    auditStatus: '',
                  region: '',
                    transactionWay: '',
                    ownerType: '',
                    ownerOrg: '',
                    licenseNoType: '',
                    buildingSize: '',
                    licenseNo: '',
                    phoneNumber: '',
                    landSize: '',
                    landDeadLine: '',
                    landPosition: '',
                    anonymous: 0,
                    volumeRate: '',
                    landType: '',
                    geoInfo: []
                },
            };
        },
        computed: {
            haveMap() {
                return this.landInfo.geoInfo && this.landInfo.geoInfo.length > 0;
            },
            landPics() {
                return this.landInfo.landPics ? this.landInfo.landPics.split(",") : [];
            },
            land_purpose() {
                return this.getDict("land_purpose");
            },
            demand_purpose() {
                // console.log(this.getDict("demand_purpose"))
                return this.getDict("demand_purpose");
            },
            transaction_way() {
                return this.getDict("transaction_way");
            },
            land_area() {
                return this.getDict("land_area");
            },
            land_owner_type() {
                return this.getDict("land_owner_type");
            },
            land_license_type() {
                return this.getDict("land_license_type");
            },
            land_type() {
                return this.getDict("land_type");
            }, isLogin() {
                return CommonService.isLogin();
            }

            ,
        },
        mounted() {
            this.loadLandInfo();
            this.checkFavorite();
        },
        methods: {
            getLandPurposeItemName(demand_purpose, id) {
                let land_purposes = useDict(demand_purpose);
                let tmp = '';
                land_purposes[demand_purpose].value.forEach((item) => {
                    if (item.value === id) {
                        tmp = item.label;
                    }
                })
                return tmp;
            },
          kd_show(state){
            if(!this.isLogin)
            {
              this.$message.info("请先登录！");
              return;
            }
              this.div_type=state;

            var d=new Date();
            var year=d.getFullYear();
            var month=change(d.getMonth()+1);
            var day=change(d.getDate());
            var hour=change(d.getHours());
            var minute=change(d.getMinutes());
            var second=change(d.getSeconds());
            function change(t){
              if(t<10){
                return "0"+t;
              }else{
                return t;
              }
            }
            var times=year+'-'+month+'-'+day;
            document.getElementById("user_date").value=times;
            CommonService.getidCardInfo().then(ress => {
              console.log(ress)
              console.log("333333")
              if (ress && ress.data) {
                if(ress.data.idCard==null || ress.data.idCard=="" || ress.data.idCard==undefined ){
                  this.$message.info("请现在个人中心进行实名验证！");
                  return;
                }else
                {
                  this.userId=ress.data.userId;
                 document.getElementById("div_tel").value=ress.data.phone;
                 document.getElementById("div_name").value=ress.data.name;
                  if( this.div_type==1)
                  {
                    document.getElementById("div_time").style.display="";
                    document.getElementById("div_number").style.display="none";
                    document.getElementById("div_lable").innerHTML="看地时间";
                    document.getElementById("div_title").innerHTML="预约看地";



                  }else{
                    document.getElementById("div_time").style.display="none";
                    document.getElementById("div_number").style.display="";
                    document.getElementById("div_lable").innerHTML="意向报价";
                    document.getElementById("div_title").innerHTML="意向报价";
                  }
                  document.getElementById("delDialog_box_new").style.display="";
                }
              }
            })

          },
          kd_hide(state){
            if(!this.isLogin)
            {
              this.$message.info("请先登录！");
              return;
            }
            this.div_type=state;
            document.getElementById("delDialog_box_new").style.display="none";


          },
          kd_from(){
              if(!this.isLogin)
              {
                this.$message.info("请先登录！");
                return;
              }


            var div_tel=document.getElementById("div_tel").value;
            var div_name=document.getElementById("div_name").value;

            if(div_tel.trim()=="")
            {
              this.$message.info("手机不能维空！");
              return;
            }

            if(div_name.trim()=="")
            {
              this.$message.info("姓名不能维空！");
              return;
            }
            var coent="";
            if( this.div_type==1)
            {
              coent= document.getElementById("user_date").value;


            }else{
              coent= document.getElementById("user_text").value;
            }
            var type=99;
            if(this.div_type==1)
            {
              if(coent.trim()=="")
              {
                this.$message.info("预约时间不能维空！");
                return;
              }

            }else
            {
              type=98;
              if(coent.trim()=="")
              {
                this.$message.info("意向报价不能为空！");
                return;
              }
              var cs=1;
              var seldata=this.data.tableData;
              for (var i=0;i<seldata.length;i++)
              {
                if(seldata[i].tel==div_tel )
                {
                  if(coent<=parseFloat(seldata[i].bj))
                  {
                    this.$message.info("意向报价不能低于上次报价！");
                    return;
                  }
                  cs=seldata[i].cs;
                  CommonService.delNew([seldata[i].id])
                  cs++;
                }
              }
            }



            var d=new Date();
            var year=d.getFullYear();
            var month=change(d.getMonth()+1);
            var day=change(d.getDate());
            var hour=change(d.getHours());
            var minute=change(d.getMinutes());
            var second=change(d.getSeconds());
            function change(t){
              if(t<10){
                return "0"+t;
              }else{
                return t;
              }
            }
            var time=year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;


            document.getElementById("user_date").value="";
            document.getElementById("user_text").value="";
            var title= this.landInfo.title+"___"+this.landInfo.id+"___"+this.userId+"___landId"+this.landInfo.createBy
            var content=div_name+"___"+div_tel+"___"+coent+"___"+""+cs;
            CommonService.land_news_add({content:content,headPic:"",id
                  :"",releaseTime:time,setTop:"",source:"无",status:0,title:title,type:type}).then((res) => {

              document.getElementById("delDialog_box_new").style.display="none";
              this.$message.success("提交成功");
              this.loadLandInfo();
            });


          },
            scrollInto(id) {
                document.getElementById(id).scrollIntoView();
            },
            gotoLink(url) {
                this.$router.push("/link?url=" + url);
            },
            addFavorite() {
                if (!CommonService.isLogin()) {
                    this.$message.info("请先登录");
                    return;
                }
                if (!this.clickState) {
                    this.clickState = true;
                    if (this.haveFavorite) {
                        CommonService.delFavorite(this.landInfo.id, "supply").then((res) => {
                            this.clickState = false;
                            if (res.code === 0) {
                                this.haveFavorite = false;
                                this.$message.success("取消收藏成功");
                            }
                        });
                    } else {

                        CommonService.addFavorite(this.landInfo.id, "supply").then((res) => {
                            this.clickState = false;
                            if (res.code === 0) {
                                this.haveFavorite = true;
                                this.$message.success("收藏成功");
                            }
                        });
                    }
                } else {
                    //  this.$message.error("点击过多！");
                }

            },
            checkFavorite() {
                if (!CommonService.isLogin()) {
                    return;
                }

                CommonService.checkFavorite(this.$route.params.id, "supply").then((res) => {
                    if (res.code === 0) {
                        this.haveFavorite = res.data;
                    }
                });
            },
            loadLandInfo() {
              var d=new Date();
              var year=d.getFullYear();
              var month=change(d.getMonth()+1);
              var day=change(d.getDate());
              var hour=change(d.getHours());
              var minute=change(d.getMinutes());
              var second=change(d.getSeconds());
              function change(t){
                if(t<10){
                  return "0"+t;
                }else{
                  return t;
                }
              }
              var times=year+'-'+month+'-'+day;
              document.getElementById("user_date").value=times;
                CommonService.getLandDetail(this.$route.params.id).then((res) => {
                    // console.log(res)
                    if (res.code === 0) {
                        if (res.data.anonymous == 1) {
                            res.data.phoneNumber = "0838-2502185";
                        } else {
                            res.data.phoneNumber = res.data.contact + "：" + res.data.phoneNumber;
                        }
                        Object.assign(this.landInfo, res.data);
                    }
                });

              CommonService.getLandNews({title:"___"+this.$route.params.id+"___",type:98}).then((res) => {
                if (res.code === 0) {
                  var arra_data=[];
                  for (var i=0;i<res.data.records.length;i++){
                   var rows= res.data.records[i];
                  var data_json=rows.content.split("___");
                  if(data_json.length>2)
                  {
                    arra_data.push(
                        {"xm":data_json[0].substr(0,1)+"*",
                          "dh": data_json[1].substr(0,3)+"****"+data_json[1].substr(7),
                          "bj":data_json[2],
                          "tel":data_json[1],
                          "id":rows.id,
                          "cs":data_json[3]==""?"1":data_json[3],
                          "releaseTime":rows.releaseTime
                        })
                  }
                  }
                }

                arra_data=arra_data.sort(function (a, b) {
                  return b.bj - a.bj;
                });
                this.data.tableData=arra_data;
                console.log(arra_data)
              })
            },
            getDict(dictName) {
                return useDict(dictName)[dictName].value;
            },
            findLabel,
        },
    };
</script>
<style scoped lang="scss">
.delDialog[data-v-02e9ab7c] {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 200;
  top: 0;
  left: 0;
}
.delDialog_box .container_box[data-v-02e9ab7c] {
  width: 370px;
  height: 190px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
  border-radius: 6px;
  border: 4px solid #cdd3dc;
  z-index: 201;
  text-align: center;
}
.delDialog_box .container_box .title_box[data-v-02e9ab7c] {
  width: 100%;
  height: 45px;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #e2e2e2;
  text-align: center;
  line-height: 45px;
}
.delDialog_box .container_box[data-v-02e9ab7c] {
  width: 370px;
  height: 190px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
  border-radius: 6px;
  border: 4px solid #cdd3dc;
  z-index: 201;
  text-align: center;
}
.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}
.epf_btn.epf_subscribe_btn {
  width: 80px;
  height: 36px;
  line-height: 36px;
  margin-right: 10px;
}
.epf_btn_bg, .epf_btn_borNew:hover {
  background: var(--lightColor);
  color: #fff;
  border: 1px solid var(--lightColor);
}
.epf_btn, .epf_btn_btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
}
.el-date-editor {
  position: relative;
  display: flow;
  text-align: left;
}
</style>